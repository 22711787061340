// Supports weights 100-900
@import '@fontsource-variable/raleway';
@tailwind base;
@tailwind components;
@tailwind utilities;

html { font-family: 'Raleway Variable', sans-serif; }

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: inherit;
}

/* React Datepicker Override  */
.react-datepicker__day {
  @apply font-bold rounded-full text-lg w-10 h-10 pt-1 #{!important};
}
.react-datepicker__header  {
  @apply bg-stone-700 #{!important};
}
.react-datepicker__day-name, .react-datepicker__current-month {
  @apply text-white #{!important};
}
.react-datepicker__day-name {
  @apply font-bold rounded-full text-lg w-10 h-10 pt-1 #{!important};
}

.react-datepicker__day--in-selecting-range {
  @apply bg-stone-400 #{!important};
}

.react-datepicker__day--in-range, .react-datepicker__day--selected {
  @apply bg-stone-700 text-slate-200 #{!important};

  &:hover {
    @apply text-white bg-stone-400 #{!important};
  }
}
.react-datepicker__close-icon {
  @apply top-[17px] #{!important};
}

.react-datepicker__close-icon::after {
  @apply w-8 h-8 bg-stone-700 text-slate-200 text-xl #{!important};
}
